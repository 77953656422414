import Vue from 'vue';
import { createInertiaApp } from '@inertiajs/vue2';
import './../sass/app.scss';
// import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { ZiggyVue } from '../../vendor/tightenco/ziggy/dist/vue.m';

import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import VuejsDialog from "@/libs/dialog/js/index.js";

const appName =
  window.document.getElementsByTagName('title')[0]?.innerText || 'Laravel';

createInertiaApp({
  title: (title) => `${title} - ${appName}`,
  resolve: (name) =>
    resolvePageComponent(
      `./Pages/${name}.vue`,
      import.meta.glob('./Pages/**/*.vue')
    ),
  // resolve: (name) => {
  //   const pages = import.meta.glob('./Pages/**/*.vue', { eager: true });
  //   return pages[`./Pages/${name}.vue`];
  // },
  setup({ el, App, props, plugin }) {
    Vue.use(plugin);
    Vue.use(ZiggyVue, Ziggy);
    Vue.use(VuejsDialog);
    Vue.prototype.$storage = window.storage;

    new Vue({
      render: (h) => h(App, props)
    }).$mount(el);
  },
  progress: {
    color: '#4B5563'
  }
});
