<script setup>
import { computed, ref } from 'vue';

const emit = defineEmits(['input']);

const props = defineProps({
  value: {
    default: null,
  },
  checkedValue: {
    default: 1,
  },
  uncheckedValue: {
    default: 0,
  },
});

const internalValue = ref(props.value ? props.checkedValue : props.uncheckedValue);

const proxyChecked = computed({
  get() {
    return props.value === props.checkedValue;
  },

  set(val) {
    internalValue.value = val ? props.checkedValue : props.uncheckedValue
    emit('input', internalValue.value);
  },
});
</script>

<template>
    <input
        type="checkbox"
        :value="internalValue"
        v-model="proxyChecked"
        class="rounded border-stone-300 dark:border-gray-700 text-region-forest shadow-sm focus:ring-region-forest"
    />
</template>
